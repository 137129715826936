import React from 'react'

/** Styles */
import { Container, Row, Col } from 'react-bootstrap'

/** i18n */
import { useTranslation } from 'react-i18next'

const Footer = () => {
	const { t } = useTranslation()

	return (
		<footer className='footer bg-dark text-light'>
			<Container className='h-100 d-flex flex-column justify-content-end'>
				<Row className='flex-grow-1 align-content-center'>
					<Col xs={12} lg={6} className='getInTouch h2'>
						{t('getInTouch')}
						<i className='arrowIcon fas fa-arrow-right fa-md m-sm-4'></i>
					</Col>

					<Col xs={12} lg={6} className='d-flex flex-column justify-content-center'>
						<Row className='mailAndPhone h5 row-cols-2'>
							<Col className='d-flex flex-column'>
								<div className='d-flex align-items-center mb-2'>
									<i className='fas fa-envelope-open-text fa-lg'></i>
									<span className='ml-3'>Email:</span>
								</div>

								<a href='mailto:eureshopde@gmail.com' className='link-wrapper white mg-top-16px w-inline-block'>
									<div className='link-text heading-h4-size'>eureshopde@gmail.com</div>
								</a>
							</Col>

							{/* <Col className='d-flex flex-column'>
								<div className='d-flex align-items-center mb-2'>
									<i className='fas fa-phone fa-lg'></i>
									<span className='ml-3'>{t('callUs')}:</span>
								</div>

								<a data-w-id='a9716d81-7fc3-0b29-1a2b-32de728b9537' href='tel:(414)977-048' className='link-wrapper white mg-top-16px w-inline-block'>
									<div className='link-text heading-h4-size'>(414) 977 - 048</div>
								</a>
							</Col> */}
						</Row>
					</Col>
				</Row>

				<Row>
					<Col className='text-center'>Copyright&copy; EureShop</Col>
				</Row>
			</Container>
		</footer>
	)
}

export default Footer
